import React from "react"
import { Link } from "gatsby"
import { PlainStructuredText } from "./common/plain-structured-text.component"
import { getLangPrefix } from "../utils/langs"
import { DEFAULT_LANG } from "../../propreties"

export const NavbarBlog = ({ items = [], lang = DEFAULT_LANG }) => (
  <section className="blog-section-nav">
    <div className="container">
      <div className="row">
        <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
          <ul className="blog-nav nav nav-pills">
            <li role="presentation">
              <Link to={`${getLangPrefix(lang)}/blog`} activeClassName="active">
                Latest Articles
              </Link>
            </li>
            {items.map(item => (
              <li role="presentation">
                <Link
                  partiallyActive={true}
                  activeClassName="active"
                  to={`${getLangPrefix(item.node._meta.lang)}/blog/${
                    item.node._meta.uid
                  }`}
                >
                  <PlainStructuredText structuredText={item.node.title} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </section>
)
