import React from "react"
import { Navbar } from "../components/navbar.component"
import Layout from "../components/layout.component"
import { NavbarBlog } from "../components/navbar.blog.component"
import { Thumbnail } from "../components/thumbnail.blog.component"
import { graphql } from "gatsby"
import { PlainStructuredText } from "../components/common/plain-structured-text.component"
import { linkFragment } from "../link-resolver"
import { Background } from "../components/common/background-image.component"
import { Slices } from "../components/slices.component"
import { RichText } from "../components/common/rich-text.component"
import SEO from "../components/common/seo.component"
import moment from "moment"

const BlogPost = ({ data }) => {
  const post = data.prismic.blogPage
  if (post) {
    const categories = data.prismic.allBlogCategorys.edges
    return (
      <Layout>
        <SEO
          title={post.page_title || post.title}
          description={post.description || post.text}
          keywords={post.keywords}
          image={post.preview_image}
          lang={post._meta.lang}
        />
        <Navbar slides={[{}]} lang={post._meta.lang}/>
        <div dir={post._meta.lang === "ar-ae" ? "rtl" : "ltr"}>
          <section className="blog-section header header-light pb20"/>
          <NavbarBlog items={categories} lang={post._meta.lang}/>
          <Background image={post.image} className="blog-banner">
            <div className="slider__wrap"/>
            <div className="container">
              <div className="row">
                <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                  <div className="blog-banner-header">

                    <h2>
                      <PlainStructuredText structuredText={post.title}/>
                    </h2>
                    <div className="blog-post-item text-white">
                      <div className="blog-post-item-details"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Background>
          <section className="blog-section pb100">
            <div className="container">
              <div className="row">
                <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                  <RichText render={post.text}/>
                  <div>{moment(post._meta.firstPublicationDate).format("MMM Do YYYY")}</div>
                </div>
              </div>
            </div>
          </section>
          <Slices body={post.body} large={false}/>
        </div>
      </Layout>
    )
  }
  return null
}
BlogPost.fragments = [linkFragment]
export default BlogPost

export const query = graphql`
    query blogPageQuery($lang: String!, $uid: String!) {
        prismic {
            blogPage(lang: $lang, uid: $uid) {
                page_title
                description
                keywords {
                    keyword
                }
                preview_image
                _meta {
                    uid
                    lang
                    type
                    firstPublicationDate
                }
                image
                category {
                    ... on PRISMIC_BlogCategory {
                        title
                        _linkType
                        _meta {
                            uid
                            lang
                            type
                        }
                    }
                }
                is_featured_article
                text
                title
                body {
                    ... on PRISMIC_BlogPageBodyPricing_plans {
                        type
                        label
                        fields {
                            price_units
                            plan_price
                            plan_name
                            plan_features
                            link_text
                            link_style
                            link {
                                ...link
                            }
                            is_free_plan
                        }
                        primary {
                            bg_image
                            bg_color
                            title
                            text
                        }
                    }
                    ... on PRISMIC_BlogPageBodyRich_text {
                        type
                        label
                        primary {
                            text
                        }
                    }
                    ... on PRISMIC_BlogPageBodyForm {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            form_script
                            form_url
                            text
                        }
                    }
                    ... on PRISMIC_BlogPageBodyFeature {
                        type
                        label
                        primary {
                            bg_image
                            bg_color
                            text
                            title
                        }
                        fields {
                            image
                            link_style
                            link_text
                            text
                            title
                            link {
                                ...link
                            }
                        }
                    }
                    ... on PRISMIC_BlogPageBodyBlock_with_text_and_image {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            min_height
                            image
                            link_style
                            link_text
                            link {
                                ...link
                            }
                            text
                            title
                        }
                    }
                }
            }
            allBlogCategorys(lang: $lang) {
                edges {
                    node {
                        title
                        _meta {
                            uid
                            lang
                            type
                        }
                    }
                }
            }
        }
    }
`
